<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Depositable Order History</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <ValidationObserver ref="createForm" v-slot="{ handleSubmit }">
                                        <form @submit.prevent="handleSubmit(saveForm)">
                                            <div class="row">
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Collected Amount" vid="collected_amount" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="collected_amount">Collected Amount <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="collected_amount" v-model="form.collected_amount" class="form-control" placeholder="Hub Cost" readonly />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Hub Cost" vid="hub_cost" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="hub_cost">Hub Cost <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="hub_cost" v-model="form.hub_cost" class="form-control" placeholder="Hub Cost" readonly />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Rider Advanced" vid="rider_advanced" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="rider_advanced">Rider Advanced <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="rider_advanced" v-model="form.rider_advanced" class="form-control" placeholder="Rider Advanced" readonly />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>                                        
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Date" vid="date" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="date">Date <span class="text-danger" title="Required">*</span></label>
                                                            <input type="date" id="date" v-model="form.date" class="form-control" required disabled />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="mobile">Amount <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="amount" v-model="form.amount" class="form-control" placeholder="Amount" required disabled />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Available" vid="available_amount" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="available_amount">Available <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="available_amount" v-model="form.available_amount" class="form-control" disabled />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div> -->
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Deposit Method" vid="deposit_method" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="deposit_method">Deposit Method <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="deposit_method"
                                                                v-model="form.deposit_method"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= depositMethods
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="form.deposit_method == 3">
                                                    <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="hub_id">Carried By <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="carried_by"
                                                                v-model="form.carried_by"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= riderList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="form.deposit_method != 3">
                                                    <div class="form-group">
                                                        <ValidationProvider name="Trnx ID" vid="trnxId" v-slot="{errors}" rules="required">
                                                            <label for="note">Trnx ID <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="trnxId" v-model="form.trnxId" class="form-control" placeholder="Transaction ID" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <ValidationProvider name="Note" vid="note" v-slot="{errors}" rules="required">
                                                            <label for="note">Note <span class="text-danger" title="Required">*</span></label>
                                                            <textarea id="note" v-model="form.note" class="form-control" placeholder="Note maximum 200 word"> </textarea>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                    <button style="margin-top:30px" type="button" class="btn btn-info" @click.prevent="depositStore" v-if="form.amount <= form.collected_amount">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="title">Rider <span class="text-danger" title="Required">*</span></label>
                                            <v-select name="hub_id"
                                                v-model="search.rider_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= riderList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                        <div class="form-group" style="margin-top:26px">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData">Search</button>
                                            <span v-if="summary" style="margin-left:10px; font-size:18px">
                                                Parcel: <b>{{ summary.total_order }},</b>
                                                Collected: <b> {{ summary.total_collected ? summary.total_collected : 0 }} Tk</b>
                                                <slot v-if="!search.rider_id">
                                                    , Previous Pending: <b> {{ form.collected_amount - summary.total_collected }} Tk,</b>
                                                    Total Collected: <b> {{ form.collected_amount }} Tk</b>
                                                </slot>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Order ID</th>
                                                    <th>Merchant</th>
                                                    <th>Customer</th>
                                                    <th>Address</th>
                                                    <th>Status</th>
                                                    <th>Price</th>
                                                    <th>Collected</th>
                                                    <th>Destination Hub</th>
                                                    <th>Order Date</th>
                                                    <th>Instruction</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ item.id }}</td>
                                                    <td>{{ item.merchant ? item.merchant.business : '' }}</td>
                                                    <td>{{ item.name }}, {{ item.mobile }}</td>
                                                    <td>{{ item.address }}</td>
                                                    <td><span :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>
                                                    <td>{{ item.price }}</td>
                                                    <td>{{ item.collected }}</td>
                                                    <td>{{ item.destination ? item.destination.name : '' }}</td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.instruction }}</td>
                                                </tr>
                                            </tbody>
                                        </table>           
                                    </div>   
                                     <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div> 
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
import config from '@/config'
import moment from 'moment'

export default {
    name:'RiderAssign',
    data(){
        return {
            loader: false,
            // listData: [],
            riderDetails: '',
            summary: null,
            search: {
                rider_id: ''
            },
            form:{
                hub_id: '',
                date  : moment().format('YYYY-MM-DD'),
                hub_cost: '',
                hub_cost_ids: '',
                rider_advanced: '',
                rider_advanced_ids: '',
                collected_amount: '',
                amount: '',
                available_amount: 0,
                deposit_method: '',
                carried_by: null,
                trnxId: '',
                note: ''
            },
            depositMethods: [
                { id: 1, text: 'Bank' },
                { id: 2, text: 'Bkash' },
                { id: 3, text: 'Cash' },
            ],
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created() {
        this.checkCostOrPendingParcel()
        this.checkPendingParcel()
        this.checkPendingParcelSummary()
    },
    watch: {
        'form.amount': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.form.available_amount = (this.getAvailableBalance(newVal) - parseFloat(this.form.hub_cost + this.form.rider_advanced))
            }
        }
    },
    computed : {
        listData () {
            return this.$store.state.list
        },
        authUser () {
            return this.$store.state.authUser
        },
        customState () {
            return this.$store.state
        },
        riderList () {
            if (this.authUser.role_id == 7) {
                return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
            }
            
            return this.$store.state.commonObj.riderList
        }
    },
    methods:{
        searchData() {
            this.checkPendingParcel()
            this.checkPendingParcelSummary()
        },
        async checkCostOrPendingParcel () {
            this.loader = true        
            const response = await config.getData(`/hub/deposit/check-cost/${this.authUser.hub_id}`)
            this.loader = false
            if (response.success){
                const available_amount = parseFloat(response.data.collected_amount - (response.data.rider_advanced + response.data.hub_cost))
                this.form = Object.assign(this.form, response.data, { amount: available_amount, available_amount: 0 })
            } else {
               this.createModal = false;
               this.$toast.error({
                    title: 'Error',
                    message: response.message,
                    color: '#dc3545'
                })
            } 
        },
        getRiderDetails (riderId) {
            const rider = this.$store.state.commonObj.riderList.find(el => el.id == riderId)
            this.riderDetails = rider ? (rider.text + ', '+ rider.mobile) : ''
        },
        async checkPendingParcel() { 
            const params = Object.assign(this.search, { hub_id: this.authUser.hub_id, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            const response = await config.getData('/hub/deposit/pending-parcel', params)
            this.loader = false
            if (response.success){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        async checkPendingParcelSummary() { 
            const params = Object.assign(this.search, { hub_id: this.authUser.hub_id })
            const response = await config.getData('/hub/deposit/pending-parcel-summary', params)
            this.loader = false
            if (response.success){
                this.summary = response.data
            } else {
                this.summary = null
            } 
        },
        getStatus (status) {
            const tmpStatus = this.customState.statusList.find(tmp => tmp.id == status)
            return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
        },
        getAvailableBalance (depositAmount) {
            return parseFloat(this.form.collected_amount - depositAmount)
        },
        async depositStore() {
            if (this.form.amount > 0) {
                this.loader = true
                this.$store.dispatch('stateReload', true)    
                this.form = Object.assign(this.form, { hub_id: this.authUser.hub_id })
                const response = await config.postData('/hub/deposit/store', this.form)
                this.loader = false
                if (response.success) {
                    window.location.reload()
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.createForm.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Please enter amount',
                    color: '#dc3545'
                })  
            }
            
        }
    }
}
</script>